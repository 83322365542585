import { postNoToken } from "./Api/PostNoToken";

export const baseUrl = 'https://api.flinktax.de'
//export const baseUrl = "http://167.86.118.58:2010";
//export const baseUrl = "http://85.215.242.68:2011";
//export const baseUrl = "http://192.168.1.98:7004";
// export const baseUrl = 'http://93.186.120.196:2011'

export const login = (form) => {
  let data = {
    email: form.email,
    password: form.password,
  };
  return postNoToken(`/Users/Login`, data);
};

export const adminLogin = (form) => {
  let data = {
    email: form.email,
    password: form.password,
  };
  return postNoToken(`/Admin/Login`, data);
};
